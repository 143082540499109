import dayjs from "dayjs"

export const relativeTime = (ts, overrideFormat = "DD·MM·YYYY HH:mm") => {
	if (!ts) return {}

	const ts_milliseconds = ts * 1000
	const _date = new Date(ts_milliseconds)
	const now = new Date().getTime()

	const tsDiff = Math.round((Math.abs(now - ts_milliseconds) / 1000))
	const minDiff = Math.round(tsDiff / 60)

	// 13/03/2023 09:44
	// 34 λ.πρίν
	return {
		dateFormatted: tsDiff <= 3 * 3600
			? minDiff < 1
				? "τώρα"
				: minDiff < 60
					? `${minDiff} λ. πριν`
					: `${Math.round(minDiff / 60)} ω. πριν`
			: dayjs(_date).format(overrideFormat),
		dateISO: _date.toISOString()
	}
}